import React from "react"
import styled from "styled-components"
import PersonalInformationBecomeAnDealerForm from "../PersonalInformationBecomeAnDealerForm"
import BreakpointUp from "../Media/BreakpointUp"

const CardPrimary = styled.div`
  background-color: #0b619b;
  color: #fff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);
`
const CardPrimaryTitle = styled.h2`
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 38px;
  }
`
const CardPrimaryBody = styled.div`
  padding: 15px;
  ${BreakpointUp.sm`	
        padding:30px;
    `}
  ${BreakpointUp.lg`	
        padding:50px;
    `} 
    .form-control {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
    &:focus,
    &:active {
      border-bottom-color: #fff;
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    }
    &:focus + .control-label {
      color: rgba(255, 255, 255, 0.5);
    }
    &:valid + .control-label {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #0b619b inset !important;
    -webkit-text-fill-color: #fff;
  }
  select.form-control option {
    color: #000;
  }
  .control-label {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
    &:focus,
    &:active {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .form-action {
    text-align: center;
    ${BreakpointUp.sm`
            margin-bottom: 0;
            position: absolute;
            left: 0;
            right: 0;
            top:100%;
        `}
    ${BreakpointUp.lg`	
            top: calc(100% + 15px);
        `}
        .btn {
      &:hover {
        border-color: #fff;
      }
    }
  }
`
const PrimaryFormBecomeAnDealer = ({ states, location }) => {
  return (
    <CardPrimary>
      <CardPrimaryBody>
        <CardPrimaryTitle>
          Get Started Today With Your Free Quote…
        </CardPrimaryTitle>
        <PersonalInformationBecomeAnDealerForm
          states={states}
          location={location}
        />
      </CardPrimaryBody>
    </CardPrimary>
  )
}

export default PrimaryFormBecomeAnDealer
